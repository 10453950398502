<template>
  <div style="margin: -15px -15px;background-color: #fff;">
    <div class="videoLib-top">
      <div style="width: 35%;margin-left: 40px">
        <span>状态：</span>
        <a-radio-group v-model="isSue" @change="stateChange">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="1">
            已发布
          </a-radio-button>
          <a-radio-button value="0">
            未发布
          </a-radio-button>
        </a-radio-group>
      </div>
      <div style="width: 35%">
        <span>类型：</span>
        <a-radio-group v-model="type" @change="typeChange">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="start">
            片头
          </a-radio-button>
          <a-radio-button value="end">
            片尾
          </a-radio-button>
        </a-radio-group>
      </div>
      <div>
        <a-button @click="lookBtn" type="primary">查询</a-button>
        <a-button @click="restBtn" style="margin-left: 15px">重置</a-button>
      </div>
    </div>
    <div class="videoLib-content">
      <div class="r-btn">
        <a-button @click="newProject" type="primary">上传视频</a-button>
      </div>
      <div>
        <a-table
            :pagination="false"
            :columns="columns"
            :data-source="data"
            :row-key="record => record.shortId">
            <span slot="name" slot-scope="text, record">
              <a @click="titleBtn(record.medurl)">{{ text }}</a>
            </span>
            <span slot="type" slot-scope="text, record">
              {{ record.type === 'start' ? '片头':'片尾' }}
            </span>
          <span slot="isIssue" slot-scope="text,record">
            <a-switch
                style="margin-right: 5px"
                v-model="record.isIssue === 1?true:false"
                checked-children="已发布"
                un-checked-children="未发布"
                @click="singleVideoSwitch(record.isIssue === 1? 0 : 1,record)"
                default-checked />
          </span>
          <span slot="action" slot-scope="text, record">
              <a @click="restBtnTab(record)">修改</a>
              <a-popconfirm
                  style="margin-left: 8px"
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="confirm(record.shortId)"
              >
                <a>删除</a></a-popconfirm>
            </span>
        </a-table>
        <div style="width: 100%">
          <div style="float: right;margin-right: 20px;margin-top: 20px">
            <a-pagination
                show-quick-jumper
                :default-current="pageNumber"
                :total="total"
                @change="onChange" />
          </div>
        </div>
      </div>
    </div>
    <!--------------------------------------------------对话框------------------------------------------->
    <a-drawer
        title="上传视频"
        :width="500"
        :visible="visible_upVideo"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_video"
    >
      <a-form-model
          ref="content_ruleForm_upVideo"
          :model="videoForm"
          :rules="content_video_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol">
        <a-form-model-item prop="video" label="视频">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_video_surface_plot"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file" accept=".mp4"
                  @change="content_surface_plot_original_video($event)"/>
            </div>
            <div
                @click="openVideoUrl(videoForm.video)"
                class="upload_LiveBroadcast">
              <video
                  style="width: 100%;height:100%"
                  :src="videoForm.video"></video>
            </div>
          </div>
          <div style="color: #a1a1a1">注：尺寸 640*360，大小不超过500kb</div>
        </a-form-model-item>
        <a-form-model-item  prop="title" label="标题">
          <a-input
              v-model="videoForm.title"
              style="width: 370px"
              placeholder="请输入标题"
          />
        </a-form-model-item>
        <a-form-model-item  prop="specialist" label="类型">
          <a-select
              v-model="videoForm.specialist"
              show-search
              placeholder="请选择类型"
              option-filter-prop="children"
              style="width: 370px"
          >
            <a-select-option
                v-for="(type,index) in typeList"
                :value="type.value">
              {{type.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch
              v-model="videoForm.isShow === 1?true:false"
              checked-children="发 布"
              un-checked-children="不发布"
              @click="switchChange(videoForm.isShow === 1? 0 : 1)"
              default-checked />
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="close_video">
          取消
        </a-button>
        <a-button
            style="margin-left: 10px"
            type="primary" @click="onsubmit_video">
          确定
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import moment from "moment";
import {update} from "../../../utils/update";
import E from "wangeditor";
import {
  get_opening_credits,
  get_signature,
  get_videoLink,
  post_video,
  put_startEnd
} from "@/service/MedicalConference_y";
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    width:'50%',
    scopedSlots: { customRender: 'name' },
  },{
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },{
    title: '状态',
    dataIndex: 'isIssue',
    key: 'isIssue',
    scopedSlots: { customRender: 'isIssue' },
  },{
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'created_time',
    width:'15%',
  },{
    title: '操作',
    key: 'action',
    width:'10%',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  name:'videoLibrary',
  data(){
    return{
      pageNumber:1,
      total:0,
      data:[],
      videoForm:{
        video:'',
        title:'',
        specialist:undefined,
        isShow:0
      },
      content_video_rules:{
        video: [{ required: true, message: '请上传视频', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        specialist: [{ required: true, message: '请选择类型', trigger: 'blur' }],
      },
      labelCol: { span: 3},
      wrapperCol: { span: 14 },
      columns,
      visible_upVideo:false,
      yearShowOne:false,
      type:'',
      isSue:'',
      vFile:'',
      signature:'',
      yunVideo_fileId:'',
      vUploader: {}, // 腾讯云上传的初始化对象
      typeList:[
        {
          id:1,
          value:'start',
          name:'片头'
        },
        {
          id:2,
          value:'end',
          name:'片尾'
        },
      ],
      tableId:0,
      listId:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle','视频片头片尾管理')
    this.getList()
  },
  methods:{
    //获取列表
    async getList(pageNum,type,isSue) {
      let data = {
        isSue:isSue,
        type:type,
        pageNum:pageNum,
        pageSize:10,
      }
      const response = await get_opening_credits(data)
      if (response.code === 0) {
        this.data = response.data.shorts
        this.total = response.data.count
      } else {
        this.$message.warning(response.message)
      }
    },
    lookBtn(){
      this.getList(this.pageNumber,this.type,this.isSue)
    },
    restBtn(){
      this.pageNumber = 1
      this.type = ''
      this.isSue = ''
      this.getList()
    },
    openVideoUrl(url){
      window.open(url)
    },
    newProject(){
      this.tableId = 0;
      this.visible_upVideo = true
    },
    titleBtn(medurl){
      window.open(medurl)
    },
    confirm(id){
      let data = {
        "delete":1
      }
      this.delList(id,data)

    },
    //删除和修改公用
    async delList(id,data) {
      const response = await put_startEnd(id,data)
      if (response.code === 0) {
        this.$message.success('操作成功~')
        //  刷新列表
        //   this.getList(this.pageNumber,this.type,this.isSue)
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    close_video(){
      this.videoForm.video = ''
      this.videoForm.title = ''
      this.videoForm.specialist = undefined
      this.videoForm.isShow = 0
      this.visible_upVideo = false

    },
    // 上传腾讯云
    uploadVideoFile_toyun(){
      let _this = this
      const getSignature = () => {
        return _this.signature;
      }
      const tcVod = new _this.$TcVod({
        getSignature: getSignature // 前文中所述的获取上传签名的函数
      })
      const mediaFile = this.vFile
      this.vUploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })
      this.vUploader.done().then(res => { // 上传完成回调
        //视频上传后获取到的视频fileId
        this.yunVideo_fileId = res.fileId
        this.content_video_url(res.fileId)
        this.$message.success('视频文件上传成功')
      })
    },
    //获取视频url地址
    async content_video_url(videoId) {
      const response = await get_videoLink(videoId)
      if (response.code === 0) {
        this.videoForm.video = response.data.mediaUrl
      }else {
        this.$message.warning(response.message)
      }
    },
    //获取签名
    async content_surface_plot_getSignature() {
      const response = await get_signature()
      if (response.code === 0) {
        this.signature = response.data.signature;
      }
    },
    async content_surface_plot_original_video(e) {
      if (e.currentTarget.files[0]) { // 选择了文件
        if (e.currentTarget.files[0].type === 'video/mp4') {
          this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息

          await this.content_surface_plot_getSignature()
          await this.uploadVideoFile_toyun()
        } else {
          this.$message.warning('仅支持mp4格式的视频上传')
        }
      } else { // 取消选择文件
        this.vFile = {}
        this.vIsFileExist = false
      }
      document.getElementById('up_video_surface_plot').value = null;
    },
    onsubmit_video(){
      this.$refs.content_ruleForm_upVideo.validate(valid =>{
        if(valid){
          let form = this.videoForm
          if(this.tableId){
            //修改
            let data = {
              fileId:this.yunVideo_fileId,
              title: form.title,//标题
              type:form.specialist,  //start:片头 end片尾
              isIssue:form.isShow //是否发布 1：是 0：否
            }
            this.delList(this.listId,data)
          }else {
            let data = {
              title: form.title,//标题
              type:form.specialist,  //start:片头 end片尾
              isIssue:form.isShow //是否发布 1：是 0：否
            }
            this.addTable(this.yunVideo_fileId,data)
          }
          this.visible_upVideo = false
          this.videoForm.video = ''
          this.videoForm.title = ''
          this.videoForm.specialist = undefined
          this.videoForm.isShow = 0
        }else {
          this.$message.warning("表单有空~")
          return false;
        }
      })



    },
    //新增
    async addTable(fileId,data) {
      const response = await post_video(fileId, 'short', data)
      if (response.code === 0) {
        this.$message.success("新增成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    stateChange(){
      this.getList(this.pageNumber,this.type,this.isSue)
    },
    typeChange(){
      this.getList(this.pageNumber,this.type,this.isSue)
    },
    switchChange(isShow){
      this.videoForm.isShow = isShow
    },
    singleVideoSwitch(isopen,row){
      let data = {
        fileId:row.fileId,
        title: row.title,//标题
        type:row.type,  //start:片头 end片尾
        isIssue:isopen
      }
      this.delList(row.shortId,data)
    },
    onChange(pageNumber){
      this.pageNumber = pageNumber
      this.getList(pageNumber,this.type,this.isSue)
    },
    restBtnTab(row){
      this.tableId = 1
      this.listId = row.shortId
      let form = this.videoForm
      form.title = row.title
      form.video = row.medurl
      form.specialist = row.type
      form.isShow = row.isIssue
      this.yunVideo_fileId = row.fileId
      this.visible_upVideo = true

    },
  },

}
</script>

<style lang="scss" scoped>
.videoLib-top{
  width: 100%;
  border-bottom: 13px solid #f0f2f5;
  height: 100px;
  display: flex;
  padding-top: 2%;
  .input{
    width: 65%;
    margin-left: 10px;
  }
}
.videoLib-content{
  min-height: 600px;
  width: 97%;
  margin: auto;
  .r-btn{
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_LiveBroadcast{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}
</style>
